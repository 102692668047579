.er_clients {
  padding-top: 54px;
  padding-bottom: 80px;

  @include respond-to(tablet-large) {
    padding-top: 30px;
    padding-bottom: 40px;
  }

  @include respond-to(phone) {
    padding-top: 26px;
    padding-bottom: 30px;
  }

  &_container {
    &_top {
      margin-bottom: 32px;

      @include respond-to(tablet-large) {
        margin-bottom: 20px;
      }
    }
  }

  &_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 0 auto;
      object-fit: contain;
    }
  }
}
