.er_footer {
  background-color: $black;
  padding: 80px 0 60px;
  user-select: none;

  @include respond-to(tablet) {
    padding: 60px 0 40px;
  }

  @include respond-to(phone) {
    padding: 40px 0 30px;
  }

  &__inner {
    &__top {
      margin-bottom: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include respond-to(tablet-large) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 60px;
      }

      @include respond-to(phone) {
        margin-bottom: 20px;
      }

      &_line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond-to(tablet-large) {
          width: 100%;
          margin-bottom: 60px;
        }

        @include respond-to(phone) {
          flex-direction: column;
          align-items: center;
          margin-bottom: 40px;
        }
      }

      &__logo {
        margin-right: 140px;

        @include respond-to(phone) {
          margin-right: 0;
          margin-bottom: 40px;
        }
      }

      &__contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include respond-to(phone) {
          align-items: center;
        }

        &__emails {
          display: flex;

          @include respond-to(mini) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          &__item {
            &:not(:last-child) {
              margin-right: 40px;

              @include respond-to(phone) {
                margin-right: 30px;
              }

              @include respond-to(mini) {
                margin-right: 0;
                margin-bottom: 12px;
              }
            }

            &__title {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: $white-50;
              margin-bottom: 4px;

              @include respond-to(phone) {
                text-align: center;
              }
            }
            &__link {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              color: $white-100;
              transition: opacity 0.55s ease-out;
              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }
    }

    &__bottom {
      border-top: 1px solid $white-50;
      padding-top: 40px;

      @include respond-to(phone) {
        padding-top: 20px;
      }

      &__copyright {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: $white-50;

        a {
          color: $white-100;
          transition: opacity 0.55s ease-out;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
